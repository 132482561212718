import React from 'react';
import { AnimationProps, PageProps } from '../../Router.tsx';
import { MainLayout } from '../MainLayout.tsx';

export const Unauthorized = (props: AnimationProps) => {
    const name = '401';
    const alias = 'Unauthorized';

    const _props: AnimationProps & PageProps = {
        ...props,
        id: 'error',
        header: name,
        subheader: alias
    }

    return <MainLayout {..._props}/>
}
