import React from 'react';
import { Parameters } from '../services/ParameterService.ts';
import { AnimationProps, PageProps } from '../Router.tsx';
import { HiIcon } from '../assets/HiIcon.tsx';
import { MainLayout } from './MainLayout.tsx';

const TextItem = (props: { text: string, description: string }) => {
    return (
        <li>
            <p>
                {props.text}
            </p>
            <p>{props.description}</p>
        </li>
    );
}

const Content = (props: AnimationProps & { name: string }) => {
    if (!props.isComplete) {
        return;
    }

    return (
        <article id='about-list' style={{ marginInline: '4rem' }}>
            <aside>
                <ul>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <HiIcon color='var(--clr-text)' size={64} />
                        {TextItem({
                            text: 'My name is ' + props.name + ', and I\'m a software developer working as a fullstack developer.',
                            description: ''
                        })}
                    </div>

                    <h2>Introduction</h2>
                    {TextItem({
                        text: 'I\'m thrilled to have you here, exploring my page. Allow me to give you a glimpse of who I am.',
                        description: 'I\'m a passionate developer who loves crafting software solutions that make a positive impact.'
                    })}

                    <h2>What to expect</h2>
                    {TextItem({
                        text: 'As you browse through my GitHub profile, you\'ll discover a collection of repositories that demonstrate my expertise in various programming languages, frameworks, and technologies.',
                        description: 'Each repository provides insights into my problemsolving abilities, coding style, and dedication to producing clean, well-documented code.'
                    })}
                    {TextItem({
                        text: 'Whether you\'re a fellow developer, a recruiter, or simply an enthusiast, I hope there\'s something here for everyone to learn and appreciate.',
                        description: 'Feel free to dive into my projects, learn from my code, and don\'t hesitate to reach out if you have any inquiries.'
                    })}

                    <h2>My Background</h2>
                    {TextItem({
                        text: 'I\'m a Computer Science developer with a strong passion for software development.',
                        description: 'I have experience with a variety of programming languages, frameworks, and tools, and I\'m always eager to learn more.'
                    })}

                    <h2>My Journey</h2>
                    {TextItem({
                        text: 'Be sure to follow along to stay updated on my future endeavors and witness my growth as a software developer.',
                        description: 'I\'m excited to share my journey with you and look forward to connecting with you along the way.'
                    })}

                    <h2>Get in Touch</h2>
                    <p>Thank you for visiting my Website, and enjoy!</p>
                </ul>
            </aside>
        </article>
    );
}

export const About = (props: AnimationProps) => {
    const alias = 'About';
    const name = Parameters.header;

    const _props: AnimationProps & PageProps = {
        ...props,
        id: 'about',
        header: alias,
        subheader: name
    };

    return (
        <MainLayout {..._props}>
            <Content {...props} name={name} />
        </MainLayout >
    )
}