import React, { useEffect, useState } from 'react';
import { Parameters, QueryType } from '../services/ParameterService.ts';

const getTheme = (isDark: boolean): "dark" | "light" => {
    return isDark ? "dark" : "light";
}

export const DarkModeButton = () => {
    const [isDark, setIsDark] = useState(Parameters.getPrefered(QueryType.ColorScheme));

    const handleOnThemeChange = () => {
        const theme = getTheme(isDark);
        Parameters.setPrefered(QueryType.ColorScheme, theme);
        window.document.body.parentElement?.setAttribute("data-theme", theme);
    }

    useEffect(handleOnThemeChange, [isDark]);

    return (
        <div className='theme-switch-wrapper'>
            <label htmlFor="theme-switch" className="theme-switch">
                <span className="theme-switch-icon-toggle-wrap">
                    <input id="theme-switch" data-darkmode-switch onChange={() => setIsDark(!isDark)} className="theme-switch-icon-toggle" type="checkbox" role="switch" name="theme" checked={isDark} />
                    <span className="theme-switch-icon">
                        <span className="theme-switch-icon-part"></span>
                        <span className="theme-switch-icon-part"></span>
                        <span className="theme-switch-icon-part"></span>
                        <span className="theme-switch-icon-part"></span>
                        <span className="theme-switch-icon-part"></span>
                        <span className="theme-switch-icon-part"></span>
                        <span className="theme-switch-icon-part"></span>
                        <span className="theme-switch-icon-part"></span>
                        <span className="theme-switch-icon-part"></span>
                    </span>
                </span>
            </label>
        </div>
    );
}
