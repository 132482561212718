import React from "react";
import { Parameters } from "../services/ParameterService.ts";
import { AnimationProps } from "../Router.tsx";
import { Divider } from "./Divider.tsx";

export const Footer = (props: AnimationProps) => {
    if (!props.isComplete) {
        return;
    }

    return (
        <>
            <Divider />
            <footer id='copyright' style={{ marginInline: '4rem', marginBlockEnd: '5rem' }}>
                <section style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p style={{ fontSize: '0.7rem', fontWeight: 200, lineHeight: 1, padding: '0.5rem' }}>
                        All Rights Reserved © 2024
                    </p>
                    <p style={{ fontSize: '0.7rem', fontWeight: 200, lineHeight: 1, padding: '0.5rem' }}>
                        Website by {Parameters.header}
                    </p>
                </section>
            </footer>
        </>
    );
}
