import React from "react";
import { NavLink } from "react-router-dom";

export const Anchor = (props: { className: string, style?: React.CSSProperties, href: string, text: string, size: string }) => {
    const defaultStyle: React.CSSProperties = {
        color: 'var(--clr-text)', textAlign: 'center', fontSize: props.size, fontWeight: 200, lineHeight: 1
    }
    return (
        <NavLink to={props.href} className={props.className} style={{ ...defaultStyle, ...props.style, }}>
            {props.text}
        </NavLink>
    );
}
