import React from 'react';
import { Parameters } from '../services/ParameterService.ts';
import { AnimationProps, PageProps } from '../Router.tsx';
import { MainLayout } from './MainLayout.tsx';

export const Main = (props: AnimationProps) => {
    const _props: AnimationProps & PageProps = {
        ...props,
        id: 'main',
        header: Parameters.header,
        subheader: Parameters.subheader
    };

    return <MainLayout {..._props} />
}
