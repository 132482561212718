import React, { CSSProperties } from "react";
import { AnimateWord } from "./Animator.tsx";
import { GetCharacterWidthPercent } from "../services/StringService.ts";
import { motion } from "framer-motion";
import { variants } from "../services/AnimationService.ts";

export const Header = (props: { header: string, subheader: string }) => {
    const headerWidth = GetCharacterWidthPercent(props.header) + 'vw';
    const subheaderWidth = GetCharacterWidthPercent(props.subheader) + 'vw';

    const headerStyle: CSSProperties = {
        fontSize: headerWidth, fontWeight: 600, lineHeight: 1
    };
    const subheaderStyle: CSSProperties = {
        textAlign: 'center',
        fontSize: subheaderWidth, fontWeight: 200, lineHeight: 1
    };

    return (
        <motion.aside
            variants={variants.PageLoad.Header}
            initial="initial"
            animate="animate">
            <h1 style={headerStyle}>
                {AnimateWord(props.header)}
            </h1>
            <h2 style={subheaderStyle}>
                {AnimateWord(props.subheader)}
            </h2>
        </motion.aside>
    );
}