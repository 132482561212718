import React from "react"

export const HiIcon = ({ color, size }: { color: string, size: number }) => {
    return (
        <svg viewBox="0 0 20 20" width={size} xmlns="http://www.w3.org/2000/svg" fill={color}>
            <g transform="translate(-140, -7639)" fill={color}>
                <g transform="translate(56, 160)">
                    <path d="M84,7488 L86,7488 L86,7485 L84,7485 L84,7488 Z M100,7479 L100,7481 L102,7481 L102,7483 L104,7483 L104,7479 L100,7479 Z M86,7481 L88,7481 L88,7479 L84,7479 L84,7483 L86,7483 L86,7481 Z M102,7497 L100,7497 L100,7499 L104,7499 L104,7495 L102,7495 L102,7497 Z M86,7495 L84,7495 L84,7499 L88,7499 L88,7497 L86,7497 L86,7495 Z M84,7493 L86,7493 L86,7490 L84,7490 L84,7493 Z M102,7493 L104,7493 L104,7490 L102,7490 L102,7493 Z M102,7488 L104,7488 L104,7485 L102,7485 L102,7488 Z M90,7499 L93,7499 L93,7497 L90,7497 L90,7499 Z M95,7499 L98,7499 L98,7497 L95,7497 L95,7499 Z M95,7481 L98,7481 L98,7479 L95,7479 L95,7481 Z M90,7481 L93,7481 L93,7479 L90,7479 L90,7481 Z M97,7495 L100,7495 L100,7483 L97,7483 L97,7495 Z M91,7495 L88,7495 L88,7483 L91,7483 L91,7487 L92,7487 L92,7483 L95,7483 L95,7495 L92,7495 L92,7491 L91,7491 L91,7495 Z"></path>
                </g>
            </g>
        </svg>
    )
}