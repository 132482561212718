import { CookiesAccepted } from "../components/CookiePopup.tsx";

export enum QueryType {
    ColorScheme,
    ReducedMotion,
    HighContrast,
    ReducedTransparency,
}

export class Parameters {
    private static readonly fullname = 'Einar van de Velde';
    private static readonly alias = 'Cusatelli';

    private static readonly hostname = window.location.hostname.toLocaleLowerCase();

    public static get isAlias() {
        return this.hostname.includes('cusatelli');
    }

    public static get header() {
        return this.isAlias ? this.alias : this.fullname;
    }

    public static get subheader() {
        return this.isAlias ? this.fullname : this.alias;
    }

    public static get email() {
        return 'contact@' + (Parameters.isAlias ? Parameters.subheader.toLowerCase() : Parameters.header.toLowerCase().split(' ').join('')) + '.com';
    }

    public static setPrefered(type: QueryType, value: string) {
        if (!CookiesAccepted()) {
            return;
        }

        let key: string;

        switch (type) {
            case QueryType.ColorScheme:
                key = 'prefers-color-scheme';
                break;
            case QueryType.ReducedMotion:
                key = 'prefers-reduced-motion';
                break;
            case QueryType.HighContrast:
                key = 'prefers-contrast';
                break;
            case QueryType.ReducedTransparency:
                key = 'prefers-reduced-transparency';
                break;
        }

        if (key) {
            localStorage.setItem(key, value);
        }
    }

    public static getPrefered(type: QueryType) {
        if (!window.matchMedia) {
            return false;
        }

        let key: string | undefined;
        let value: string | undefined;
        switch (type) {
            case QueryType.ColorScheme:
                key = 'prefers-color-scheme';
                value = 'dark';
                break;
            case QueryType.ReducedMotion:
                key = 'prefers-reduced-motion';
                value = 'reduce';
                break;
            case QueryType.HighContrast:
                key = 'prefers-contrast';
                value = 'more';
                break;
            case QueryType.ReducedTransparency:
                key = 'prefers-reduced-transparency';
                value = 'reduce';
                break;
        }

        if (key && value) {
            if (CookiesAccepted()) {
                const localStorageItem = localStorage.getItem(key);
                if (localStorageItem) {
                    return localStorageItem === value;
                }
            }

            return window.matchMedia(`(${key}: ${value})`).matches;
        }

        return false;
    }
}
