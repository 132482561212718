import { Variants } from "framer-motion";

export const variants = {
    Word: {
        Container: {
            initial: {
                position: 'relative'
            },
            animate: {
                transition: {
                    staggerChildren: 0.05
                }
            },
        } as Variants,
        Character: {
            initial: {
                position: 'relative',
                left: '50rem',
                opacity: 0
            },
            animate: {
                left: '0rem',
                opacity: 1,
                transition: {
                    ease: "easeInOut",
                    duration: 1
                }
            },
        } as Variants,
    },
    PageLoad: {
        Main: {
            initial: {
                opacity: 0,
            },
            animate: {
                opacity: 1,
                transition: {
                    delay: 0.25,
                    ease: "easeInOut",
                    duration: 1.5,
                }
            }
        } as Variants,
        Dialog: {
            initial: {
                top: '-20rem',
                opacity: 0,
            },
            animate: {
                top: '1rem',
                opacity: 1,
                transition: {
                    ease: "easeInOut",
                    duration: 1.5
                }
            }
        } as Variants,
        Header: {
            initial: {
                opacity: 0,
                scale: 1,
            },
            animate: {
                opacity: 1,
                scale: [2, 1],
                transition: {
                    delay: 0.25,
                    ease: "easeInOut",
                    duration: 1.5,
                }
            }
        } as Variants,
    },
    Navbar: {
        initial: {
            bottom: '-5rem',
        },
        animate: {
            bottom: 0,
            transition: {
                ease: "easeInOut",
                duration: 1
            }
        }
    } as Variants,
}
