import React from "react";
import { motion } from "framer-motion";
import { SplitWord } from "../services/StringService.ts";
import { variants } from "../services/AnimationService.ts";

export const AnimateWord = (text: string) => {
    return (
        <motion.span variants={variants.Word.Container}>
            {
                SplitWord(text).map((textChar, index) => (
                    <motion.span variants={variants.Word.Character} key={text + '-' + index}>
                        {textChar}
                    </motion.span>
                ))
            }
        </motion.span>
    );
}
