import React from 'react';
import { motion } from 'framer-motion';
import { Footer } from '../components/Footer.tsx';
import { Header } from '../components/Header.tsx';
import { Loader } from '../components/Loader.tsx';
import { Navbar } from '../components/Navbar.tsx';
import { variants } from '../services/AnimationService.ts';
import { AnimationProps, PageProps } from '../Router.tsx';
import { Contact } from '../components/Contact.tsx';
import { DarkModeButton } from '../assets/DarkModeButton.tsx';
import { CookiePopup } from '../components/CookiePopup.tsx';

export const MainLayout = (props: React.JSX.IntrinsicAttributes & AnimationProps & PageProps & { children?: React.ReactNode }) => {
    return (
        <motion.main
            variants={variants.PageLoad.Main}
            initial="initial"
            animate="animate"
            onAnimationComplete={() => props.setIsComplete(true)}>

            <DarkModeButton />

            <Navbar {...props} />
            <CookiePopup {...props} />

            <article id={props.id}>
                <Loader {...props} />
                <Header header={props.header} subheader={props.subheader} />
            </article>

            <div>
                {props.children}
            </div>

            <Contact {...props} />
            <Footer {...props} />

        </motion.main>
    )
}