import React from 'react';
import { ExternalIcon } from '../assets/ExternalIcon.tsx';
import { NavLink } from 'react-router-dom';
import { AnimationProps, PageProps } from '../Router.tsx';
import { Divider } from '../components/Divider.tsx';
import { MainLayout } from './MainLayout.tsx';

const ProjectListItem = (props: { link: string, title: string, description: string, isExternal: boolean }) => {
    return (
        <li>
            <NavLink to={props.link} target='_blank' className="button link">
                <p>
                    {props.title}
                    {props.isExternal && (
                        <span style={{ float: 'right' }}>
                            <ExternalIcon color={'var(--clr-text)'} />
                        </span>
                    )}
                </p>
                <p>{props.description}</p>
            </NavLink>
        </li>
    );
}

const ProjectList = (props: AnimationProps) => {
    if (!props.isComplete) {
        return;
    }

    return (
        <>
            <Divider />
            <article id='projects-list' style={{ marginInline: '4rem' }}>
                <aside>
                    <ul>
                        <h2>Games</h2>
                        <li style={{ background: 'var(--clr-text) url("https://portfolio-cusatelli-2023.web.app/resources/games/endless-runner/about/EndlessRunnerRelease.png") bottom / 120% no-repeat', padding: '0', overflow: 'hidden' }}>
                            <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)', padding: '1rem', color: 'var(--clr-text-secondary)' }}>
                                <p style={{ textDecoration: 'none', paddingBlockStart: '0.5rem', paddingInline: '1rem', fontSize: '2rem', textTransform: 'uppercase', fontWeight: 200, fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif' }}>
                                    # Endless Runner
                                    <span style={{ float: 'right' }}>
                                        <ExternalIcon color={'var(--clr-text-secondary)'} />
                                    </span>
                                </p>
                                <ul style={{ display: 'flex' }}>
                                    <li style={{ padding: '0.5rem 1rem', color: 'var(--clr-text-secondary)' }}>
                                        <NavLink to='https://portfolio-cusatelli-2023.web.app/games/endless-runner' target='_blank' className="button link">
                                            <p style={{ textDecoration: 'none' }}>
                                                <span style={{ paddingRight: '1rem' }}>◈</span>Play
                                            </p>
                                        </NavLink>
                                    </li>
                                    <li style={{ padding: '0.5rem 1rem', color: 'var(--clr-text-secondary)' }}>
                                        <NavLink to='https://portfolio-cusatelli-2023.web.app/games/about/endless-runner' target='_blank' className="button link">
                                            <p style={{ textDecoration: 'none' }}>
                                                <span style={{ paddingRight: '1rem' }}>◈</span>About
                                            </p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>

                        </li>
                        <h2>Websites</h2>
                        <ProjectListItem link='https://portfolio-cusatelli-2023.web.app/' title='Portfolio 2023' description='v2023 of Portfolio Website' isExternal={true} />
                        <ProjectListItem link='https://portfolio-cusatelli.web.app/' title='Portfolio 2021' description='v2021 of Portfolio Website' isExternal={true} />
                        <ProjectListItem link='https://ekens-bygg.web.app/' title='EkensBygg' description='Website for a local construction company based in Växjö, Sweden' isExternal={true} />
                        <ProjectListItem link='https://linnea-andersson.web.app/' title='Tolouä el Shäms' description='A website for a small kennel breeding Saluki in Skåne, Sweden' isExternal={true} />

                        <h2>Artwork</h2>
                        <ProjectListItem link='https://www.artstation.com/artwork/oAWowB/' title='Avatar' description='A 3D model avatar, made in Blender 2.81' isExternal={true} />
                        <ProjectListItem link='https://www.artstation.com/artwork/d8gN8e/' title='Shamali Karamah al Mahir' description='3D model of a Saluki running in the desert, made in Blender 2.81' isExternal={true} />
                    </ul>
                </aside>
            </article>
        </>
    );
}

export const Projects = (props: AnimationProps) => {

    const _props: AnimationProps & PageProps = {
        ...props,
        id: 'projects',
        header: 'Projects',
        subheader: ''
    };

    return (
        <MainLayout {..._props}>
            <ProjectList {...props} />
        </MainLayout>
    )
}
