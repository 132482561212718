import { motion } from "framer-motion";
import React from "react";
// import { Anchor } from "./Anchor.tsx";
import { AnimationProps } from "../Router.tsx";
import { variants } from "../services/AnimationService.ts";
import { NavLink } from "react-router-dom";

export const Navbar = (props: AnimationProps) => {
    if (!props.isComplete) {
        return;
    }

    return (
        <motion.nav variants={variants.Navbar} initial='initial' animate='animate' id='nav'>
            <span className="nav-wrapper">
                <NavLink to="/" className="nav-link">Home</NavLink>
                <NavLink to="/about" className="nav-link">About</NavLink>
                <NavLink to="/techstack" className="nav-link">Techstack</NavLink>
                <NavLink to="/projects" className="nav-link">Projects</NavLink>
                {/* <Anchor className="nav-link" href={'/'} text={'Home'} size='1rem' />
                <Anchor className="nav-link" href={'/about'} text={'About'} size='1rem' />
                <Anchor className="nav-link" href={'/techstack'} text={'Techstack'} size='1rem' />
                <Anchor className="nav-link" href={'/projects'} text={'Projects'} size='1rem' /> */}
            </span>
        </motion.nav>
    );
}
