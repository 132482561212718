import React, { Dispatch, SetStateAction, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Projects } from './pages/Projects.tsx';
import { Techstack } from './pages/Techstack.tsx';
import { Main } from './pages/Main.tsx';
import { About } from './pages/About.tsx';
import { ErrorPage } from './pages/error/ErrorPage.tsx';
import { Unauthorized } from './pages/error/Unauthorized.tsx';
import { AnimatePresence } from 'framer-motion';

export type AnimationProps = {
    isComplete: boolean;
    setIsComplete: Dispatch<SetStateAction<boolean>>;
}

export type PageProps = AnimationProps & {
    id: string,
    header: string,
    subheader: string,
};

export const Router = () => {
    const location = useLocation();
    const [isComplete, setIsComplete] = useState(false);

    const props: AnimationProps = { isComplete, setIsComplete };

    return (
        <AnimatePresence onExitComplete={() => setIsComplete(false)}>
            <Routes location={location} key={location.key}>

                <Route path='/' element={<Main {...props} />}></Route>
                <Route path='/about' element={<About {...props} />}></Route>
                <Route path='/projects' element={<Projects {...props} />}></Route>
                <Route path='/techstack' element={<Techstack {...props} />}></Route>
                {/* <Route path='/contact' element={<Contact {...props} />}></Route> */}

                <Route path='/admin' element={<Unauthorized {...props} />}></Route>

                <Route path='*' element={<ErrorPage {...props} />} />

            </Routes>
        </AnimatePresence>
    );
}
