import React from 'react';
import { AnimationProps, PageProps } from '../Router.tsx';
import { Divider } from '../components/Divider.tsx';
import { MainLayout } from './MainLayout.tsx';

const StackItem = (header: string, items: string[], key: string) => {
    return (
        <span key={key}>
            <h2 className='text' style={{ marginTop: '1rem', fontWeight: 600 }}>
                {header}
            </h2>
            <p className='text' style={{ fontWeight: 200 }}>
                {
                    items.map((item, index) => {
                        if (index === items.length - 1) {
                            return `#${item}`;
                        }

                        return `#${item} `;
                    })
                }
            </p>
        </span>
    )
}

const Stack = (props: AnimationProps) => {
    if (!props.isComplete) {
        return;
    }

    const Languages = [
        'Java', 'C-Sharp', 'Cpp', 'Kotlin', 'Python',
        'Javascript', 'TypeScript',
        'HTML5', 'HTML', 'CSS', 'SASS'
    ];

    const Frameworks = [
        'ReactJS', 'React', 'Vue', 'Vue3', 'AngularJS', 'Angular',
    ];

    const Testing = [
        'XUnit', 'MSTest', 'NSubstitute', 'Jest', 'Playwright'
    ];

    const Database = [
        'NoSQL', 'MySQL', 'PostgreSQL',
        'Firebase', 'AWS', 'Azure', 'GoogleCloud'
    ];

    const CI_CD = [
        'OctopusDeploy', 'TeamCity', 'GithubActions'
    ];

    const GameDev = [
        'Unity', 'Blender'
    ];

    const Tools = [
        'Git', 'GitHub', 'BitBucket', 'Jira',
        'IntelliJ', 'Rider', 'Eclipse', 'Atom', 'VisualStudio', 'VisualStudioCode',
        'Kibana', 'OpenSearch', 'Graphana', 'MSQLServerManagementStudio', 'SSMS'
    ];

    const Headers = {
        'Programming Languages & Frameworks': Languages.concat(Frameworks),
        'Testing & Quality Assurance': Testing,
        'Database & Cloud Services': Database,
        'CI/CD': CI_CD,
        'Game Development': GameDev,
        'Tools': Tools
    }

    return (
        <>
            <Divider />
            <article style={{ marginInline: '4rem' }}>
                <div className="content" key='techstack-list'>
                    <div className='techstack'>
                        {Object.entries(Headers).map(([header, items], index) => StackItem(header, items, `techstack-item-${index}`))}
                    </div>
                </div>
            </article>
        </>
    )
}

export const Techstack = (props: AnimationProps) => {
    const name = 'Tech';
    const alias = 'stack';

    const _props: AnimationProps & PageProps = {
        ...props,
        id: 'techstack',
        header: name,
        subheader: alias
    };

    return (
        <MainLayout {..._props}>
            <Stack {...props} />
        </MainLayout>
    )
}
