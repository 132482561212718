import React, { useState } from "react";
import { motion } from "framer-motion";
import { AnimationProps } from "../Router";
import { CookieIcon } from "../assets/CookieIcon.tsx";
import { CloseIcon } from "../assets/CloseIcon.tsx";
import { variants } from "../services/AnimationService.ts";

export const CookiesAccepted = () => {
    return CheckCookies(true);
}

const CheckCookies = (accept: boolean) => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        if (cookies[i].trim().startsWith(`cookie=${accept ? 'accept' : 'reject'}`)) {
            return true;
        }
    }

    return false;
}

export const CookiePopup = (props: AnimationProps) => {
    const [isOpen, setIsOpen] = useState(!CookiesAccepted() && !CheckCookies(false));
    const [showPolicy, setShowPolicy] = useState(false);

    if (!props.isComplete) {
        return;
    }

    const setCookiesAccept = (accepted: boolean) => {
        document.cookie = `cookie=${accepted ? 'accept' : 'reject'};max-age=604800;`; // 1 week
    }

    const handleAcceptCookies = () => {
        setCookiesAccept(true);
        setIsOpen(false);
    }

    const handleRejectCookies = () => {
        setCookiesAccept(false);
        setIsOpen(false);
    }

    const handleShowPolicy = () => {
        setShowPolicy(!showPolicy);
    }

    return (
        <motion.dialog
            variants={variants.PageLoad.Dialog}
            className="cookie-overlay"
            open={isOpen}>
            <div style={{ position: 'absolute', right: '1rem', top: '1rem', cursor: 'pointer' }} onClick={() => setIsOpen(false)}>
                <CloseIcon color="#fff" size={16} />
            </div>
            <div className="cookie-content flex space-between">
                <CookieIcon color="#000000" />
                <p>By clicking the 'Accept' button below, you agree to our <button style={{ background: 'none', border: 'none', color: 'inherit', font: 'inherit', cursor: 'pointer' }} onClick={handleShowPolicy}>'<strong>Cookie Policy</strong>'</button>.</p>
            </div>

            {showPolicy &&
                <div className="cookie-content cookie-policy" style={{ position: 'relative', marginBottom: '1rem' }}>
                    <div className='flex' style={{ alignItems: 'flex-start', flexDirection: 'column' }}>
                        <p>This website uses cookies to ensure you get the best experience on our website.</p>
                        <p>We do not use cookies for tracking purposes. You can choose to accept or reject cookies.</p>
                        <p>All cookies are stored locally on your device for 1 week.</p>
                    </div>
                </div>
            }

            <div className="cookie-action flex flex-end">
                <button className="accept-cookies" onClick={handleAcceptCookies}>Accept</button>
                <button className="reject-cookies" onClick={handleRejectCookies}>Reject</button>
            </div>
        </motion.dialog>
    );
}
